import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AutoFabricService } from '@netfoundry-ui/feature/auto-fabric';
import { AddModalComponent } from '@netfoundry-ui/feature/views/modals';
import { NETWORK_SERVICE, NetworkServiceV2, ProcessExecutionServiceV2 } from '@netfoundry-ui/shared/apiv2';
import { AuthorizationService, LoginService } from '@netfoundry-ui/shared/authorization';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { Network, NetworkGroup, NetworkV2, ProcessExecution } from '@netfoundry-ui/shared/model';
import {
    ApiService,
    FeatureService,
    LoggerService,
    MenuService,
    RefresherService,
    URLS,
    ZITI_URLS,
} from '@netfoundry-ui/shared/services';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-side-buttonbar',
    templateUrl: './side-button-bar.component.html',
    styleUrls: ['./side-button-bar.component.scss'],
})
export class SideButtonBarComponent implements OnInit, OnDestroy {
    @Output() showGrowlersEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() openAppMenu: EventEmitter<boolean> = new EventEmitter();
    @Output() appSelected: EventEmitter<boolean> = new EventEmitter();
    area = '';
    currentTenant: any = {};
    confirmDialog;
    confirmString = 'app-confirm';
    usersname = '';
    popup = '';
    subscription = new Subscription();
    photo = '';
    items = [];
    network: Network | NetworkV2 = new Network({});
    currentOrg = new NetworkGroup({});
    processActive = false;
    canListNetworks = false;
    processGrowlerShown = false;
    processRefreshInterval = 10000;
    processPollingInit = false;
    subscriptionDetails: any = {};
    showBilling = false;
    isCancelledAccount = false;
    isSelfService = false;
    billingStatusInvalid = false;
    networkStatus = '';
    paymentProfile;
    currentAutoFabric = [];
    autoFabricProvisioned = false;

    showAppMenu = false;

    private headerRefresher;
    private processRefresher;

    constructor(
        private menu: MenuService,
        private router: Router,
        public apiService: ApiService,
        public authorizationService: AuthorizationService,
        public featureService: FeatureService,
        public refresher: RefresherService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private growlerService: GrowlerService,
        private processExecutionService: ProcessExecutionServiceV2,
        private dialogForm: MatDialog,
        private loginService: LoginService,
        private loggerService: LoggerService,
        private autoFabricSerivce: AutoFabricService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.popup = '';
            }
        });

        this.headerRefresher = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    ngOnInit() {
        this.usersname = localStorage.getItem('profile_nick');
        this.photo = localStorage.getItem('profile_picture');

        this.subscription.add(
            this.apiService.currentTenant.subscribe((tenant) => {
                this.currentTenant = tenant;
            })
        );

        this.subscription.add(
            this.menu.areaSource.pipe(debounceTime(500)).subscribe((area) => {
                this.area = area;
                this.popup = '';
            })
        );

        this.subscription.add(
            this.apiService.currentNetwork.subscribe((data) => {
                this.network = data;
                this.refresh();
            })
        );

        this.subscription.add(
            this.apiService.currentOrg.subscribe((data) => {
                this.currentOrg = data;
                if (localStorage.getItem(`nf_new_self_service_${this.currentOrg.name}`) === 'true') {
                    this.isSelfService = true;
                }
            })
        );

        this.subscription.add(
            this.apiService.currentPaymentProfile.subscribe((data) => {
                this.paymentProfile = data;
            })
        );

        this.subscription.add(
            this.apiService.billingStatusInvalid.subscribe((status: boolean) => {
                this.billingStatusInvalid = status;
            })
        );

        this.apiService.currentSubscription.subscribe((subscription) => {
            this.subscriptionDetails = subscription;
            this.isSelfService = this.featureService.isSelfServiceAccount(subscription);
            this.showBilling = this.isSelfService && this.authorizationService.canViewPaymentProfile();
            this.isCancelledAccount = this.featureService.isCancelledAccount(subscription);
        });

        this.subscription.add(
            this.apiService.currentAutoFabric.subscribe((data) => {
                this.currentAutoFabric = data;

                if (this.currentAutoFabric?.length > 0) {
                    this.currentAutoFabric.forEach((router) => {
                        if (router.status === 'PROVISIONED' || router.status === 'ERROR') {
                            this.autoFabricProvisioned = true;
                        }
                    });
                }
            })
        );
    }

    refreshProcesses() {
        clearTimeout(this.processRefresher);

        const oldest = moment().subtract(30, 'minutes');
        const options = {
            params: {
                networkId: this.network.id,
                status: 'RUNNING',
                startedAt: encodeURIComponent('>=' + oldest.toISOString()),
            },
            pageParams: {
                size: 1,
                page: 0,
            },
        };
        this.processExecutionService.getProcessExecutionPage(options, 'startedAt').then(
            (processes) => {
                this.processActive = processes.length > 0;
                this.processRefresher = setTimeout(() => {
                    this.refreshProcesses();
                }, this.processRefreshInterval);
            },
            () => {
                this.loggerService.error('Failed to retrieve process executions.');
            }
        );
    }

    open(area) {
        if (this.menu.areas.includes(area)) {
            if (area === 'ZAC' && (this.area === 'Network' || this.area === 'Organization')) {
                this.router.navigate([ZITI_URLS.ZITI_DASHBOARD]);
            } else if (area === 'Network' && this.area === 'ZAC') {
                this.router.navigate([URLS.V7DASHBOARD]);
            }
            if (this.area === area) {
                this.menu.setArea('');
                this.area = '';
            } else {
                this.menu.setArea(area);
                this.area = area;
            }
        } else if (area === 'Super') {
            this.router.navigate([URLS.USER]);
        } else if (area === 'Add') {
            this.dialogForm.open(AddModalComponent, { data: {}, height: '500px', width: '800px', autoFocus: false });
        } else if (area === 'Alerts') {
            this.showGrowlersEvent.emit();
        } else if (area === 'Profile') {
            this.popup = 'profile';
        } else if (area === 'Processes') {
            this.router.navigate(['/process-executions']);
        } else if (area === 'Search') {
            this.router.navigate(['/attributes-explorer']);
        } else if (area === 'Billing') {
            this.router.navigate(['/billing']);
        } else if (area === 'GettingStarted') {
            this.router.navigate([URLS.GETTING_STARTED]);
        }
    }

    getTotal(num) {
        if (num > 99) {
            return '>99';
        } else {
            return num;
        }
    }

    closePopUp() {
        this.popup = '';
    }

    hover(id) {
        this.popup = id;
    }

    getGrowlerMessages() {
        const growlerErrorString = sessionStorage.getItem('growlerErrors');
        if (growlerErrorString !== null && growlerErrorString !== undefined && growlerErrorString !== '') {
            const growlerErrorObject = JSON.parse(sessionStorage.getItem('growlerErrors'));
            this.items = growlerErrorObject['growlerMessages'];
        }
    }

    confirmLogout() {
        const data = {
            title: 'Log out of your account',
            appendId: 'Logout',
            subtitle: 'To continue, please tap the "Log out" button.',
            icon: 'Logout',
            action: 'LOG OUT',
        };
        this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '225px',
            width: '600px',
            autoFocus: false,
        });
        this.confirmDialog.beforeClosed().subscribe((result) => {
            // if the result has a property loggingOut, rather than being just a boolean value, the user is being
            //  logged out of the console and we should close the dialog without continuing
            if (result === undefined || result === false) {
                this.confirmed(false);
            } else if (result['loggingOut'] === undefined) {
                if (this.area !== '') {
                    this.open(this.area);
                }
                this.confirmed(result);
            }
        });
    }

    confirmed(event) {
        if (event) {
            const data = {
                title: 'Logging Out',
                appendId: 'Logout',
                subtitle: 'Please wait while you are logged out of the system...',
                icon: 'Logout',
            };
            this.confirmDialog = this.dialogForm.open(ConfirmComponent, {
                data: data,
                height: '225px',
                width: '600px',
                autoFocus: false,
            });
            _.delay(() => {
                this.loginService.logout(false);
            }, 100);
        }
    }

    @HostListener('document:click', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleKeyboardEvent(event: MouseEvent) {}

    canCreateAny() {
        if (this.isCancelledAccount) {
            return false;
        }

        if (this.apiService.getNetworkVersion(this.network) < 7) {
            return (
                this.authorizationService.canCreateNetworks() ||
                this.authorizationService.canCreateAppWans() ||
                this.authorizationService.canCreateServices() ||
                this.authorizationService.canCreateEndpoints() ||
                this.authorizationService.canCreateEndpointGroups()
            );
        } else {
            return (
                !this.apiService.isNetworkClusterReadOnly() &&
                (this.authorizationService.canCreateNetworks() ||
                this.authorizationService.canCreateAppWans() ||
                this.authorizationService.canCreateServices() ||
                this.authorizationService.canCreateEndpoints() ||
                this.authorizationService.canCreateEdgeRouters() ||
                this.authorizationService.canCreateEdgeRouterPolicies())
            );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.refresher.disableRefresh();
        clearTimeout(this.processRefresher);
        clearTimeout(this.headerRefresher);
    }

    refresh() {
        clearTimeout(this.headerRefresher);
        this.getCurrentNetwork();

        this.headerRefresher = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
    }

    getCurrentNetwork() {
        if (this.network.id !== null && this.apiService.getNetworkVersion(this.network) >= 7) {
            const options = {
              params: {
                embed: 'clusters'
              }
            };
            this.networkServiceV2.getNetwork(this.network.id, options).then((nw: NetworkV2) => {
                this.network = nw;
                this.networkStatus = nw?.status;
                if (!this.processPollingInit) {
                    this.refreshProcesses();
                    this.processPollingInit = true;
                }
            });
        }
    }

    openApps() {
        this.showAppMenu = true;
        this.openAppMenu.emit();
    }
}
