<div class="fullModal opened" id="PublicClientAddModal">
    <div [hidden]="isComplete" class="fullRow">
        <div class="title" style="filter: brightness(50%)">
            <span *ngIf="model.id">Edit Your OIDC Public Client</span>
            <span *ngIf="!model.id">Create A New OIDC Public Client</span>
        </div>
        <div *ngIf="model.id" class="subtitle">Update your oidc public client attributes below</div>
        <div *ngIf="!model.id" class="subtitlealt">
            Public clients authenticate users by redirecting them to an identity provider and receiving an ID token and access token upon successful authentication.
        </div>
        <div class="sized">
            <div id="NetworkField">
                <div class="label row">Oidc Issuer Id<span class="note">Required</span></div>
                <div class="row">
                    <input
                        [(ngModel)]="model.oidcIssuerId"
                        id="EditZRouterName"
                        placeholder="Place your issuer ID here."
                        type="text"
                    />
                    <span [hidden]="!errors['oidcIssuerId']" class="err">
                        {{ errors['oidcIssuerId'] }}
                    </span>
                </div>
            </div>
            <div class="label row">Client Id<span class="note">Required</span></div>
            <div class="row">
                <input
                    [(ngModel)]="model.clientId"
                    id="EditZRouterName"
                    placeholder="Place your Client Id here."
                    type="text"
                />
                <span [hidden]="!errors['clientId']" class="err">
                    {{ errors['clientId'] }}
                </span>
            </div>
            <div class="label row">Client Secret<span class="note">Required</span></div>
            <div class="row">
                <input
                    [(ngModel)]="model.clientSecret"
                    id="EditZRouterName"
                    placeholder="Place your client secret here."
                    type="text"
                />
                <span [hidden]="!errors['clientSecret']" class="err">
                    {{ errors['clientSecret'] }}
                </span>
            </div>
            <div class="label row">Authorization Endpoint<span class="note">Required</span></div>
            <div class="row">
                <input
                    [(ngModel)]="model.authorizationEndpoint"
                    id="EditZRouterName"
                    placeholder="Place your authorization endpoint here."
                    type="text"
                />
                <span [hidden]="!errors['authorizationEndpoint']" class="err">
                    {{ errors['authorizationEndpoint'] }}
                </span>
            </div>
            <div class="label row">Token Endpoint<span class="note">Required</span></div>
            <div class="row">
                <input
                    [(ngModel)]="model.tokenEndpoint"
                    id="EditZRouterName"
                    placeholder="Place your token endpoint here."
                    type="text"
                />
                <span [hidden]="!errors['tokenEndpoint']" class="err">
                    {{ errors['tokenEndpoint'] }}
                </span>
            </div>
            <div class="label row">User Info Endpoint<span class="note">Optional</span></div>
            <div class="row">
                <input
                    [(ngModel)]="model.userInfoEndpoint"
                    id="EditZRouterName"
                    placeholder="Place your user info endpoint here."
                    type="text"
                />
                <span [hidden]="!errors['userInfoEndpoint']" class="err">
                    {{ errors['userInfoEndpoint'] }}
                </span>
            </div>
            <div class="label row">Restricted To Organization Ids<span class="note">Optional</span></div>
            <div class="row">
                <p-multiSelect [options]="organizationList" optionValue="id" [(ngModel)]="selectedIds" optionLabel="name" placeholder="Select Organizations"></p-multiSelect>
            <span [hidden]="!errors['restrictedToOrganizationIds']" class="err">
                {{ errors['restrictedToOrganizationIds'] }}
            </span>
            </div>
            <div class="label row">
                Activate Public Client
                <div
                    class="infoicon"
                    matTooltip="Sets this public client active for your organization."
                    matTooltipPosition="below"
                ></div>
                <span class="note">REQUIRED</span>
            </div>
            <div class="row">
                <div class="encryptionpod">
                    <span class="encryptiontext">Activate Public Client</span>
                    <div
                        (click)="toggleActive()"
                        [ngClass]="{
                            on: model.active,
                        }"
                        [title]="'Activate This Public Client'"
                        class="toggle"
                        style="float: right; margin-top: 0.9375rem; margin-right: 0.75rem"
                    >
                        <span [hidden]="!model.active" style="margin-left: -0.9375rem">YES</span>
                        <span [hidden]="model.active" style="margin-right: -0.9375rem">NO</span>
                        <div class="switch"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="label row">Active<span class="note">Required</span></div>
            <div class="row">
            </div> -->

            <div class="row buttonRow right" style="margin-top: 1.25rem">
                <button
                    (click)="save()"
                    *ngIf="!processing && (!model.id || canEdit)"
                    class="save"
                    id="SaveZitiEndpointButton"
                >
                    {{ model.id ? 'Update' : 'Create' }}
                </button>
                <button *ngIf="processing" class="save confirm" disabled>Saving <span class="spinner"></span></button>
            </div>
            <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
                <div class="buttonLabel">ESC</div>
            </div>
        </div>
    </div>

    <div [hidden]="!isComplete" class="fullRow" id="Stage3">
        <div class="confetti">
            <!--confetti div starts-->
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>

            <div class="title congratulations" style="filter: brightness(50%)">Congratulations!</div>
            <div class="subtitlealt">
                Your oidc public client has been created. You may edit it at anytime under oidc public clients.
            </div>
            <div class="row center">
                <div class="congratsicon"></div>
            </div>
            <div class="row center" style="margin-top: 1.5625rem">
                <button (click)="closeDialog()" [routerLink]="Oidc_Public_Clients" class="save">Oidc Public Clients</button>
            </div>
        </div>

        <div (click)="hide()" class="buttonBall close" id="EscZEndpointClose">
            <div class="buttonLabel">ESC</div>
        </div>
        <app-form-support-button *ngIf="!hideHelp"></app-form-support-button>
    </div>
    <!--confetti div ends-->
</div>
