import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {BrandingModeService, LoggerService} from '@netfoundry-ui/shared/services';
import { ActivatedRoute } from '@angular/router';
import { PrivateKeyService } from './private-key.service';
import {isArray, find} from 'lodash';
import { MessageService } from 'primeng/api';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { HttpErrorResponse } from '@angular/common/http';
import {Environment, ENVIRONMENT} from "@netfoundry-ui/shared/model";


@Component({
    selector: 'app-private-key-upload-form',
    templateUrl: './private-key-upload-form.component.html',
    styleUrls: ['./private-key-upload-form.component.scss'],
    providers: [MessageService]
})
export class PrivateKeyUploadFormComponent implements OnInit {
    cancelAction = 'Oops, no get me out of here';
    id;
    token;
    value;
    errors = {} as any;
    isValid = true;
    isSuccessful = false;

    loading = true;
    brandingUpdated = false;
    constructor(
        private logger: LoggerService,
        private route: ActivatedRoute,
        private growlerService: GrowlerService,
        public privateKeyService: PrivateKeyService,
        private brandingService: BrandingModeService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
    }

    ngOnInit(): void {
        this.isSuccessful = false;
        this.id = this.route.snapshot.paramMap.get('id');
            // Capture the query parameter 'token'
        this.route.queryParamMap.subscribe(params => {
        this.token = params.get('token');
      });
      const hostname = window.location.hostname;
      const isVanityUrl = hostname !== this.environment.originDomain;
      if (!isVanityUrl) {
          this.loading = false;
          this.brandingUpdated = true;
          return;
      }
      this.handleBranding(hostname)
    }

    handleBranding(hostname: string) {
        const tennantLabel = hostname.split('.')[0];
        const html = document.getElementsByTagName('html')[0];
        this.brandingService.brandingUpdated.subscribe(() => {
          this.loading = false;
        });
        setTimeout(() => {
            this.loading = false;
        }, 5000);
    }

   async submitStoredSecret() {

        if(await this.validate()) {
            if(this.id && this.token) {
                this.privateKeyService.setPrivateKey(this.id, this.token, this.value).subscribe((result) => {
                    if(result === null) {
                        this.growlerService.show(new GrowlerData('success', 'Success', 'Private Key saved successfully'));
                        this.isSuccessful = true;
                    } else {
                        this.growlerService.show(new GrowlerData('error', 'Error', 'Private Key failed to save'));
                    }

                },
                (httpErrorResponse) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Private Key failed to save. ',
                            httpErrorResponse.error.errors[0]
                        )
                    );
                }
            )
            }
        }

    }

    async validate() {
        this.id = this.route.snapshot.paramMap.get('id');
        // Capture the query parameter 'token'
        this.route.queryParamMap.subscribe(params => {
            this.token = params.get('token');
        });

        this.errors = {};
        this.isValid = true;
        if(!this.value) {
            this.errors.value = 'Key cannot be empty';
            this.isValid = false;
            return;
        }
        const errors = await this.privateKeyService.validateKey(this.id, this.token, this.value);
        if (!isArray(errors)) {
            return false;
        }

        if (find(errors, (e) => e.path === '$.value')) {
            this.errors.value = find(errors, (e) => e.path === '$.value').message;
            this.isValid = false;
        }
        if (find(errors, (e) => e.path === '$.token')) {
            this.errors.token = find(errors, (e) => e.path === '$.token').message;
            this.isValid = false;
        }
        return errors.length === 0;
    }
}
