import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Network, Notification, OidcPublicClient } from '@netfoundry-ui/shared/model';
import { NotificationService } from '@netfoundry-ui/shared/notification';
import { LoggerService, NetworkGroupService, NetworkService, OidcPublicClientService, URLS } from '@netfoundry-ui/shared/services';
import { Subscription, take } from 'rxjs';
import { NETWORK_SERVICE, NetworkServiceV2 } from '@netfoundry-ui/shared/apiv2';

import _ from 'lodash';
import { GrowlerData, GrowlerService } from '@netfoundry-ui/shared/growler';
import { AuthorizationService } from '@netfoundry-ui/shared/authorization';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-oidc-public-client-form',
    templateUrl: './oidc-public-client-form.component.html',
    styleUrls: ['./oidc-public-client-form.component.scss'],
})
export class OidcPublicClientFormComponent implements OnInit {
    Oidc_Public_Clients = URLS.OIDC_PUBLIC_CLIENTS;

    errors = {};
    processing = false;
    canEdit = true;
    networkId: string;
    organizationList = [];
    selectedIds = [];
    emails = '';
    networkGroupName;
    isComplete = false;
    isBuilding;
    hideHelp = false;
    initialModel: OidcPublicClient = new OidcPublicClient();

    public model: OidcPublicClient = new OidcPublicClient();

    private subscription = new Subscription();

    constructor(
        public oidcPublicClientService: OidcPublicClientService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<OidcPublicClientFormComponent>,
        public networkService: NetworkService,
        @Inject(NETWORK_SERVICE) private networkServiceV2: NetworkServiceV2,
        private router: Router,
        public growlerService: GrowlerService,
        public authorizationService: AuthorizationService,
        private networkGroupService: NetworkGroupService,
        private logger: LoggerService,
    ) {
  
    }

    ngOnInit() {

        this.networkGroupService.get().pipe(take(1)).subscribe((data) => {
            this.organizationList = data;
        })
        this.initModel();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    hide(response?) {
        this.dialogRef.close(response);
    }



    async save() {
        if(await this.validate()) {
            if (this.model.id) {
                this.oidcPublicClientService.update(this.model.id, this.model).subscribe((data) => {
                    this.processing = false;
                    this.dialogRef.close();
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'OIDC Public Client Updated Successfully',
                        )
                    );
                },
                (httpErrorResponse) => {
                    this.logger.error('Error from oidc public client patch', httpErrorResponse);
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'OIDC Public Client update request failed. ',
                            httpErrorResponse.error.errors[0]
                        )
                    );
                }
            )} else {
                this.oidcPublicClientService.create(this.model).subscribe((data) => {
                    this.logger.info('Create network response: ', data);
                    this.processing = true;
                    this.growlerService.show(
                        new GrowlerData(
                            'success',
                            'Success',
                            'OIDC Public Client Created Successfully',
                        )
                    );
                    this.isComplete = true;
                },
                (httpErrorResponse) => {
                    this.processing = false;
                    this.logger.error('Error from oidc public client create', httpErrorResponse);
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'OIDC Public Client create request failed. ',
                            httpErrorResponse.error.errors[0]
                            )
                        );
                    }
                )

            }
        }
    }

    paste(event: ClipboardEvent) {
        event.preventDefault(); 
        const data = event.clipboardData.getData('Text').trim();
      
        this.model.restrictedToOrganizationIds.push(...data.split(' '));
        this.model.restrictedToOrganizationIds = [...new Set(this.model.restrictedToOrganizationIds)];

    }


    onKeyDown(event: KeyboardEvent) {
        if (event.key === ' ') {
            event.preventDefault();
            const element = event.target as HTMLElement;
            element.blur();
            element.focus();
        }
    }

    toggleActive() {
  
        this.model.active = !this.model.active;
    }

    async validate() {
        this.errors = {};
        if(!this.model.id) {
            this.model.restrictedToOrganizationIds = this.selectedIds;
            const errors = await this.oidcPublicClientService.validateCreate(this.model);
            if(!_.isArray(errors)) {
                return true;
            }
            if (_.find(errors, (e) => e.path.includes('$.tokenEndpoint'))) {
                this.errors['tokenEndpoint'] = (_.find(errors, (e) => e.path.includes('tokenEndpoint'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.clientId'))) {
                this.errors['clientId'] = (_.find(errors, (e) => e.path.includes('clientId'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.clientSecret'))) {
                this.errors['clientSecret'] = (_.find(errors, (e) => e.path.includes('clientSecret'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.restrictedToOrganizationIds'))) {
                this.errors['restrictedToOrganizationIds'] = (_.find(errors, (e) => e.path.includes('restrictedToOrganizationIds'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.userInfoEndpoint'))) {
                this.errors['userInfoEndpoint'] = (_.find(errors, (e) => e.path.includes('userInfoEndpoint'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.authorizationEndpoint'))) {
                this.errors['authorizationEndpoint'] = (_.find(errors, (e) => e.path.includes('authorizationEndpoint'))).message
            }
            if (_.find(errors, (e) => e.path.includes('.oidcIssuerId'))) {
                this.errors['oidcIssuerId'] = (_.find(errors, (e) => e.path.includes('oidcIssuerId'))).message;
            }
            return errors.length === 0;
        } else {
            this.model.restrictedToOrganizationIds = this.selectedIds;
            const errors = this.oidcPublicClientService.validateUpdate(this.model.id, this.model);
            if(!_.isArray(errors)) {
                return true;
            }
            if (_.find(errors, (e) => e.path.includes('$.tokenEndpoint'))) {
                this.errors['tokenEndpoint'] = (_.find(errors, (e) => e.path.includes('tokenEndpoint'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.clientId'))) {
                this.errors['clientId'] = (_.find(errors, (e) => e.path.includes('clientId'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.clientSecret'))) {
                this.errors['clientSecret'] = (_.find(errors, (e) => e.path.includes('clientSecret'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.restrictedToOrganizationIds'))) {
                this.errors['restrictedToOrganizationIds'] = (_.find(errors, (e) => e.path.includes('restrictedToOrganizationIds'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.userInfoEndpoint'))) {
                this.errors['userInfoEndpoint'] = (_.find(errors, (e) => e.path.includes('userInfoEndpoint'))).message;
            }
            if (_.find(errors, (e) => e.path.includes('.authorizationEndpoint'))) {
                this.errors['authorizationEndpoint'] = (_.find(errors, (e) => e.path.includes('authorizationEndpoint'))).message
            }
            if (_.find(errors, (e) => e.path.includes('.oidcIssuerId'))){
                this.errors['oidcIssuerId'] = (_.find(errors, (e) => e.path.includes('oidcIssuerId'))).message;
            }
            return errors.length === 0;
    
            }
        }
    
    private initModel() {
        if (this.data.model) {
            this.initialModel = _.cloneDeep(this.data.model);
            this.model = this.data.model;
        }
        }
}
