export class OidcPublicClient {
    id: string;
    oidcIssuerId: string;
    clientId: string;
    clientSecret: string;
    restrictedToOrganizationIds: any;
    authorizationEndpoint: string;
    tokenEndpoint: string;
    userInfoEndpoint: string;
    active: boolean;
    createdBy: string;
    createdAt: string;
    deletedAt: string;
    selected = false;
    deletedBy: string;
    deleted: boolean;
}
