export const environment = {
    domain: 'sandbox',
    hsId: 23538888,
    disablev6: true,
    // originDomain: 'console.sandbox.netfoundry.io', // Allowed Origin in auth0 (root domain)
    originDomain: 'sandbox-nfconsole.io',
    originPort: false,
    production: false,
    gatewayUrl: 'gateway.sandbox.netfoundry.io',
    apiUrl: 'https://gateway.sandbox.netfoundry.io/rest/v1/',
    clientSyncsApiUlr: 'https://gateway.sandbox.netfoundry.io/clientsync/v1/',
    netflowUrl: 'https://gateway.sandbox.netfoundry.io/netflow/v1/',
    downloadsURL: 'https://netfoundry.io/resources/support/downloads/networkversion',
    customerURL: 'https://gateway.sandbox.netfoundry.io/customer/v1/',
    appsURL: 'https://gateway.sandbox.netfoundry.io/apps/v1/',
    bannerURL: 'https://gateway.sandbox.netfoundry.io/banner/v1/',
    kibanaURL: 'https://kibana-sandbox-netfoundry-io.kb.us-east-1.aws.found.io:9243/',
    elasticConfig: {
        usePublicApi: true,
        reportingServiceUrl: 'https://gateway.sandbox.netfoundry.io/reporting/v1/elastic',
        reportingServiceEnabled: true,
        reportingTemplateServiceUrl: 'https://gateway.sandbox.netfoundry.io/reporting/v1/template-search',
    },
    defaultNetworkConfigName: 'small',
    defaultSize: 'small',
    defaultLocationCode: 'us-east-1',
    authconfig: {
        clientID: '6WXzPe5Zu3Z0VjJDbwZcsZ71JeGRDJOp',
        domain: 'netfoundry-sandbox.auth0.com',
        // callbackURL: 'https://console.sandbox.netfoundry.io/callback',
        callbackURL: 'https://sandbox-nfconsole.io/callback',

        audience: 'https://gateway.sandbox.netfoundry.io/',
        // Namespace for where the organization ID is stored in the auth token
        jwtOrgKey: 'https://netfoundry.io/organization_id',
        newSignupRedirect: 'https://sandbox-nfconsole.io/signup-return',
    },
    identityConfig: {
        url: 'https://gateway.sandbox.netfoundry.io/identity/v1/',
        invitationUrl: 'https://sandbox-nfconsole.io/invitation',
        intermediateReturnUrl: 'https://sandbox-nfconsole.io/invitation',
        registrationReturnUrl: 'https://sandbox-nfconsole.io/signup',
        loginReturnUrl: 'https://sandbox-nfconsole.io/login',
    },
    billingConfig: {
        // this is subject to change
        billingUrl: 'https://gateway.sandbox.netfoundry.io/billing/v1/',
        // stripe test environment
        stripe: 'pk_test_gU2Exz3cYBQVOlnMMilWk8lv',
    },
    chargebeeConfig: {
        // this is subject to change
        site: 'nfdev-test',
        // chargebee test environment
        publishableKey: 'test_nb8p3DDkbd1HXn01ytWiY603cu1WNC1fcd',
    },
    // temporary property for the how long the session timeout should be. This should get replaced once the API supports session timeout
    defaultSessionTimeoutSeconds: 1800,
    // temporary property for how long before the session timeout the user should be warned about the timeout
    defaultTimeoutWarningSeconds: 60,
    // two hour timeout for endpoint session users
    endpointSessionTimeoutSeconds: 7200,
    pageSize: 30,
    // list of comma separated email ids of test robots. If the current profile's email id is matching with any of the given test user
    // email ids, then some of the functionalities will be hidden or diabled.
    internalTestUserEmailIds: 'nf_test1@netfoundry.io',
    logoClass: 'developmentSandbox',
    faviconUrl: '/assets/icons/SandboxFavicon.png',
    authorizationUrl: 'https://gateway.sandbox.netfoundry.io/auth/v1/',
    reportingUrl: 'https://gateway.sandbox.netfoundry.io/reports/v1',
    gtmLink:
        'https://www.googletagmanager.com/ns.html?id=GTM-TG778K4&gtm_auth=BTsoI30FTSHGf1EIISN_vQ&gtm_preview=env-5&gtm_cookies_win=x',
    v2apiUrl: 'https://gateway.sandbox.netfoundry.io/core/v2/',
    v3apiUrl: 'https://gateway.sandbox.netfoundry.io/core/v3/',
    notificationUrl: 'https://gateway.sandbox.netfoundry.io/notification/v1',
    registrationIp: '35.174.100.69, 18.235.59.244',
    // TODO: remove when MOP can handle custom timeouts
    pldtTimeoutSeconds: 32000,
    pldtId: 'a37f806b-4f49-48f2-aa28-d850d5f24b8c',
    sampleServiceUrl: 'https://sample.tools.netfoundry.io',
    hideSubmitTicket: false,
    hideZiggy: false
};
