import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableHeaderDefaultComponent } from '@netfoundry-ui/feature/data-table';
import { OrganizationPromptComponent } from '@netfoundry-ui/feature/form/organization-prompt';
import { CsvDownloadService, TableFilterService } from '@netfoundry-ui/feature/shared-services';
import { UserformComponent } from '@netfoundry-ui/page/identities';
import { AuthorizationService, IamService } from '@netfoundry-ui/shared/authorization';
import { Environment, ENVIRONMENT, Tenant, OidcAudience, OidcPublicClient } from '@netfoundry-ui/shared/model';
import { ApiService, FeatureService, LoggerService, OidcAudienceService, OidcPublicClientService, RefresherService } from '@netfoundry-ui/shared/services';
import {OidcPublicClientFormComponent} from '@netfoundry-ui/feature/form/oidc-public-client-form';
import { ConfirmComponent } from '@netfoundry-ui/ui/confirm';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { GrowlerService, GrowlerData } from '@netfoundry-ui/shared/growler';

const columns = ['name', 'siteUrl', 'isActive'];
const filename = 'audiences';
const translateStatus = true;

@Component({
    selector: 'app-oidc-public-clients',
    templateUrl: './oidc-public-clients.component.html',
    styleUrls: ['./oidc-public-clients.component.scss'],
})
export class OidcPublicClientsComponent implements OnInit, OnDestroy {
    model: OidcPublicClient;

    dialogRef;
    items = [];
    oidcPublicClients = [] as OidcPublicClient[];
    openIndex = -1;
    isLoading;

    allToggled = false;;

    isDeleteAction = false;

    subscription: Subscription = new Subscription();

    page = 1;
    filterString = '';
    deleting = 0;
    pageSize = 30;
    totalElements = 0;
    sorting = 'name';
    ordering = 'asc';
    private deleteMe = [];
    enableDeleteProtection;
    hideAppButton = false;
    changedPage: boolean;
    updatedOidcPublicClient;
    filterHasChanged: boolean;
    hasSomeDeletePermission = true;
    filterApplied: any;
    showNoData: boolean;
    columnDefs;
    columnFilters: any = {

    };
    showList = true;
    public selectedIds = [];
    private deletedIds = [];
    private inDeletingStatusCount = 0;
    private openId = '';
    private noDeleteAuthCount = 0;


    constructor(
        private oidcPublicClientService: OidcPublicClientService,
        private logger: LoggerService,
        public dialogForm: MatDialog,
        public authorizationService: AuthorizationService,
        private refresher: RefresherService,
        public filterService: TableFilterService,
        private csvDownloadService: CsvDownloadService,
        public featureService: FeatureService,
        private growlerService: GrowlerService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {}



initTableColumns() {
    const columnFilters = this.columnFilters;
    const headerComponentParams = {
        filterType: 'TEXTINPUT',
        columnFilters,
    };
    this.columnDefs = [
        {
            colId: 'oidcIssuerId',
            width: 350,
            minWidth: 100,
            field: 'oidcIssuerId',
            sortColumn: this.sort.bind(this),
            headerName: 'Issuer Id',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'clientId',
            width: 350,
            minWidth: 100,
            field: 'clientId',
            sortColumn: this.sort.bind(this),
            headerName: 'Client Id',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'authorizationEndpoint',
            width: 350,
            minWidth: 100,
            field: 'authorizationEndpoint',
            sortColumn: this.sort.bind(this),
            headerName: 'Authorization Endpoint',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'tokenEndpoint',
            width: 350,
            minWidth: 100,
            field: 'tokenEndpoint',
            sortColumn: this.sort.bind(this),
            headerName: 'Token Endpoint',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'userInfoEndpoint',
            width: 350,
            minWidth: 100,
            field: 'userInfoEndpoint',
            sortColumn: this.sort.bind(this),
            headerName: 'User Info Endpoint',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'restrictedOrgIds',
            width: 350,
            minWidth: 100,
            field: 'restrictedToOrganizationIds',
            sortColumn: this.sort.bind(this),
            headerName: 'Restricted To Organization Ids',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
        {
            colId: 'active',
            width: 350,
            minWidth: 100,
            field: 'active',
            sortColumn: this.sort.bind(this),
            headerName: 'Active',
            onCellClicked: (params) => {
                if (params?.nfTextSelected) {
                    return;
                }
                this.open(params.data);
            },
            headerComponent: TableHeaderDefaultComponent,
            resizable: true,
            cellClass: 'nf-cell-vert-align tCol',
            filter: true,
        },
    ];


}   
ngOnInit() {
    this.filterService.setPageSize(this.pageSize);

    // subscribing to the filter service to handle the change in page
    this.subscription.add(
        this.filterService.setPageEvent.subscribe((pageNum) => {
            this.page = pageNum;
            this.changedPage = true;
            this.isLoading = true;
            this.refresh();
        })
    );


    // subscribing to the filter service to handle search filter changes
    this.subscription.add(
        this.filterService.setFilterEvent.subscribe((filterString) => {
            this.filterString = filterString;
            this.isfilterApplied();
            this.refresh();
        })
    );

    this.isLoading = true;
    this.handlePermissionCheck()
    this.initTableColumns();


}

handlePermissionCheck() {
    if (this.authorizationService.canListOIDCPublicClient()) {
        this.getOidcPublicClients();
    } else {
        this.isLoading = false;
        this.showList = false;
        this.oidcPublicClients = [];
    }
}



ngOnDestroy() {
    this.columnFilters = {};
    this.subscription.unsubscribe();
    this.filterService.reset();
    this.refresher.disableRefresh();
}

create() {
    this.dialogRef = this.dialogForm.open(OidcPublicClientFormComponent, {
        data: {},
        minHeight: '100%',
        minWidth: '100%',
        height: '100%',
        width: '100%',
    });
    this.dialogRef.afterClosed().subscribe(() => {
        this.getOidcPublicClients();
    });
}

open(item: OidcPublicClient) {
    this.model = item;
    this.dialogRef = this.dialogForm.open(OidcPublicClientFormComponent, {
        data: { model: this.model },
        minHeight: '100%',
        minWidth: '100%',
        height: '100%',
        width: '100%',
    });
}

refresh() {
    this.refresher.disableRefresh();
    this.handlePermissionCheck();
}


filterChanged(event) {
    if (event.columnId === 'name') {
        this.filterString = event.value;
    }
    _.set(this.columnFilters, event.columnId, event.value);
    this.isLoading = true;
    this.isfilterApplied();
    this.shouldShowNoData();
    this.refresh();
}

shouldShowNoData() {
    this.showNoData = this.oidcPublicClients.length <= 0 && !this.filterApplied && !this.isLoading;
}

isfilterApplied() {
    this.filterHasChanged = true;
    this.filterApplied = _.some(this.columnFilters, (value) => !_.isEmpty(_.toString(value)));
}



getOidcPublicClients() {

    if (this.filterString && this.page !== 1 && !this.changedPage) {
        this.changedPage = true;
        this.isLoading = true;
        this.filterService.setPage(1, true);
        this.page = 1;
    }

    this.oidcPublicClientService
        .findOidcPublicClients()
        .subscribe((oidcPublicClients) => {
            const newTotalPages = this.oidcPublicClientService.lastPageCount;
            // if the number of pages has decreased and the user is past the last page now
            if (this.page > newTotalPages && newTotalPages !== 0) {
                // move back one page
                this.filterService.setPage(newTotalPages);
            } else {
                this.inDeletingStatusCount = 0;
                const theResult = oidcPublicClients as OidcPublicClient[];
                let newHasSomeDeletePermission = false;
                this.noDeleteAuthCount = 0;

                let updatedOidcPublicClient;
                const updatedOidcPublicClientId = this.updatedOidcPublicClient?.id;

                let index = 0;
                const newSelectedIds = [];
                for (const oidcPublicClient of this.oidcPublicClients as OidcPublicClient[]) {
                    if (this.openId === oidcPublicClient.id) {
                        this.open(oidcPublicClient);
                        this.openId = '';
                    }
                    if (oidcPublicClient.id === updatedOidcPublicClientId) {
                        this.updatedOidcPublicClient.selected = oidcPublicClient['selected'];
                        const links = _.get(theResult[index], '_links');
                        _.set(this.updatedOidcPublicClient, '_links', links);
                        theResult[index] = this.updatedOidcPublicClient;
                    }
                    if (this.selectedIds.indexOf(oidcPublicClient.id) > -1) {
                        newSelectedIds.push(oidcPublicClient.id);
                        oidcPublicClient.selected = true;
                    }
                    const actions = [];

                    // TODO add permission checks
                    if (
                        this.authorizationService.canDeleteOIDCPublicClient(oidcPublicClient.id)
                    ) {
                        actions.push('delete');
                        newHasSomeDeletePermission = true;
                    } else {
                        this.noDeleteAuthCount++;
                    }
                    // TODO add permission checks
                    if (
                        this.authorizationService.canEditOIDCPublicClient(oidcPublicClient.id)
                    ) {
                        actions.push('update');
                    }
                    if (this.featureService.powerUserEnabled) {
                        actions.push('json');
                    }
                    oidcPublicClient['actionList'] = actions;

                    index ++;
                }

                this.selectedIds = newSelectedIds;
                this.updatedOidcPublicClient = null;
                // the deleted service ids are only needed for the first refresh. After the first refresh they should no longer appear
                // if the service does appear again, then something went wrong with the delete and the status shouldn't be set to 800
                this.deletedIds = [];
                this.oidcPublicClients = theResult;

                this.totalElements = this.oidcPublicClientService.lastTotalCount;
                this.showList = this.totalElements > 0;

                this.filterService.setTotalElements(this.oidcPublicClients.length);
                this.filterService.updateTotalPages()

                this.isDeleteAction = this.anyToggled();
                this.hasSomeDeletePermission = newHasSomeDeletePermission;

                this.isLoading = false;
                this.logger.info('retrieved oidc public clients');
            }
        },
        (httpErrorResponse) => {
            this.logger.info(httpErrorResponse)
        }
    )

        .add(() => {
            this.filterHasChanged = false;
            this.isLoading = false;
            this.changedPage = false;
            this.shouldShowNoData();
        });
        this.refresher.refreshTimerId = setTimeout(() => {
            this.refresh();
        }, this.refresher.refreshInterval);
}


delete(item: OidcPublicClient) {
    if (item['id']) {
        this.oidcPublicClientService.delete(item['id']).subscribe(() => {
            this.getOidcPublicClients();
        });
    }
}


toggleAll() {
    this.allToggled = !this.allToggled;
    this.selectedIds = [];

    for (const item of this.oidcPublicClients) {
        item['selected'] = this.allToggled;
        if (this.allToggled) {
            this.selectedIds.push(item['id']);
        }
    }

    this.isDeleteAction = this.anyToggled();
}

toggle(item: OidcPublicClient) {
    item.selected = !item.selected;
    this.enableDeleteProtection = this.featureService.enableDeleteProtection;

    if (this.allToggled) {
        this.allToggled = !this.allToggled;
    }

    const index = this.selectedIds.indexOf(item.id);
    if (index > -1) {
        this.selectedIds.splice(index, 1);
    } else {
        this.selectedIds.push(item.id);
    }
    this.isDeleteAction = this.anyToggled();
    if (!this.enableDeleteProtection) {
        this.isDeleteAction = true;
        this.hideAppButton = false;
    }
    if (this.selectedIds.length > 1 && this.enableDeleteProtection) {
        this.isDeleteAction = false;
        this.hideAppButton = true;
    }
    if (this.selectedIds.length === 1 && this.enableDeleteProtection) {
        this.isDeleteAction = true;
        this.hideAppButton = false;
    }
    if (this.selectedIds.length === 0) {
        this.hideAppButton = false;
        this.isDeleteAction = false;
    }
}

anyToggled() {
    if (this.selectedIds.length > 0) {
        if (this.selectedIds.length === this.items.length) {
            this.allToggled = true;
        }
        return true;
    }
    this.allToggled = false;
    return false;
}



openConfirm(item: OidcPublicClient) {
        const bulletList = []
        const deletingInfo = this.getDeletingTotal();
        bulletList.push(deletingInfo.ids)
        this.enableDeleteProtection = this.featureService.enableDeleteProtection;

        const data = {
            title: 'Delete',
            appendId: 'Oidc-Public-Client',
            subtitle: deletingInfo.deleteString,
            bulletList: deletingInfo.ids,
            icon: 'Delete',
            action: 'Yes',
        };
        this.dialogRef = this.dialogForm.open(ConfirmComponent, {
            data: data,
            height: '340px',
            width: '600px',
            autoFocus: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
            // if the result has a property loggingOut, rather than being just a boolean value, the user is being
            //  logged out of the console and we should close the dialog without continuing
            if (result === undefined) {
                this.confirmed(false);
            } else if (result['loggingOut'] === undefined) {
                this.confirmed(result);
            }
        });
    
}

deleteSingleItem(item: OidcPublicClient) {
    const subtitle = 'Are you sure you would like to delete the following public client?:';

    this.enableDeleteProtection = this.featureService.enableDeleteProtection;

    const data = {
        title: 'Delete',
        appendId: 'Oidc-Public-Client',
        subtitle: subtitle,
        bulletList: [item],
        icon: 'Delete',
        action: 'Yes',
        isDestructive: this.enableDeleteProtection,
        itemName: item.id
    };
    this.dialogRef = this.dialogForm.open(ConfirmComponent, {
        data: data,
        height: '340px',
        width: '600px',
        autoFocus: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
            // User canceled. Do nothing.
        } else if (result['loggingOut'] === undefined) {
            this.deletedIds = [];
            this.oidcPublicClientService.delete(item.id).subscribe(
                (data) => {
                    this.deletedIds.push(item.id);
                    this.deleteMe = [];
                    this.isLoading = true;
                    this.refresh();
                },
                (error) => {
                    this.growlerService.show(
                        new GrowlerData(
                            'error',
                            'Error',
                            'Public Client failed to delete',
                            'The selected public client failed to delete from the system'
                        )
                    );
                }
            );
        }
    });
}


confirmDelete(item) {
    this.toggleAll();
    if (this.allToggled) {
        this.toggleAll();
    }

    item.selected = true;
    const index = this.selectedIds.indexOf(item.id);
    if (index === -1) {
        this.selectedIds.push(item.id);
    }
    this.isDeleteAction = true;
    this.openConfirm(item);
}

confirmed(event) {
    if (event) {
        this.isLoading = true;
        this.deleting = this.selectedIds.length;
        for (const item of this.oidcPublicClients) {
            if (item['selected']) {
                this.deleteMe[this.deleteMe.length] = item;
            }
        }
        this.deleteNext();
    } else {
        this.selectedIds = [];
        this.isDeleteAction = false;
        this.getOidcPublicClients();
    }
}

getDeletingTotal() {
    let total = 0;
    const ids = []

    for (const item of this.oidcPublicClients) {
        if (item.selected) {
            ids.push(item);
            total++;
        }
    }

    let deleteString = 'Are you sure you would like to delete ';

    if (total > 1) {
        deleteString += `these ${total} Oidc Public Clients:`;
    } else {
        deleteString += 'the following Oidc Public Clients:';
    }

    return {
        deleteString: deleteString,
        ids: ids,
    };
}

deleteNext() {
    this.deleting--;
    this.oidcPublicClientService.delete(this.deleteMe[this.deleting].id).subscribe(
        () => {
            this.postDelete();
        },
        () => {
            this.postDelete();
        }
    );
}

postDelete() {
    // forcing the deleted items to become untoggled
    this.toggle(this.deleteMe[this.deleting]);

    if (this.deleting === 0) {
        this.deleteMe = [];
        this.isLoading = true;
        this.getOidcPublicClients();
    } else {
        this.deleteNext();
    }
}

sort(sortBy) {
    if (this.sorting === sortBy) {
        if (this.ordering === 'asc') {
            this.ordering = 'desc';
        } else {
            this.ordering = 'asc';
        }
    } else {
        this.ordering = 'asc';
        this.sorting = sortBy;
    }
}

getSortClass(id) {
    if (id === this.sorting) {
        return this.ordering;
    } else {
        return '';
    }
}

}
