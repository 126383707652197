import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Environment, ENVIRONMENT } from '@netfoundry-ui/shared/model';
import { catchError, delay } from 'rxjs/operators';
import { Observable, of as observableOf } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

import _ from 'lodash';
import { HTTP_CLIENT, TokenService } from './token.service';
import { LoggerService } from './logger.service';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class OidcPublicClientService {
    apiUrl;
    preferences;
    preferenceId;
    organization;
    user;

    lastPageCount = 0;
    lastTotalCount = 0;
    constructor(
        private logger: LoggerService,
        private apiService: ApiService,
        private tokenService: TokenService,
        @Inject(HTTP_CLIENT) private http: HttpClient,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
        this.apiUrl = this.environment.identityConfig.url
        this.apiService.currentTenant.subscribe((organization) => {
            this.organization = organization;
        });
        this.apiService.currentUser.subscribe((user) => {
            this.user = user;
        });
    }

    public findOidcPublicClients() {
        const fullpath = `${this.apiUrl}/oidc-public-clients`;
            return this.http
                .get(fullpath)
                .pipe(catchError((error) => this.handleError(this, error)));
    }

    public getOidcPublicClientById(id): Observable<any> {
        const url = `${this.apiUrl}oidc-public-clients/${id}`;
        return this.http.get(url) as Observable<any>;
    }

    public create(model) {
        const fullpath = `${this.apiUrl}/oidc-public-clients`;
            return this.http
                .post(fullpath, model)
                .pipe(catchError((error) => this.handleError(this, error)));
    }



    public update(id, model) {
        const fullpath = `${this.apiUrl}/oidc-public-clients/${id}`;
            return this.http
                .patch(fullpath, model)
      
    }


    public delete(id: string) {
        const fullpath = `${this.apiUrl}/oidc-public-clients/${id}`;
            return this.http
                .delete(fullpath)
    }

    public async validateCreate(model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .post(this.apiUrl + 'oidc-public-clients/', model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();
    }
    public async validateUpdate(id: string, model: any) {
        const headers = new HttpHeaders().set('Accept', 'application/json').set('nf-validate', '');
        return this.http
            .patch(this.apiUrl + 'oidc-public-clients/' + id, model, {
                headers: headers,
                responseType: 'json',
            })
            .toPromise();

    }


    /**
     * Generic error handler for bad requests
     */
    protected handleError(scope: any, error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            scope.logger.error('Feature Service error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            scope.logger.error(`Feature Backend returned code ${error.status}`, error.error);
        }
        scope.lastErrorSource.next(error);
        return observableThrowError(error);
    }

}
